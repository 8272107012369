
.rootBody{
  margin: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex; 
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-image: url('../public/bdd-background.png');
  /* background-repeat: no-repeat; */
  background-size: cover;
  /* overflow: auto; */
}
.once {
  /* display: flex; */
  flex: 1;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  margin-top: 10px;
  min-width: 22%;
  height: auto;
}
.once:hover {
  transform: scale(1.2);
}

#moreMenu {
  width: 20px;
  height: 20px;
  color: white;
  float: right;
  z-index: 1;
  position: relative
}
#moreMenu:hover {
  transform: scale(1.2);
}

.circle-container {
  position: relative;
  width: 180px;
  height: 180px;
}

.circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
}

.circle-inner {
  /* background: linear-gradient(45deg, #00fbfe, #00fbfe); */
  box-shadow: 3px 3px 15px #00fbfe inset;
}

.circle-middle {
  /* background: #f2f2f2; */
  z-index: -1;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 60px;
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  position: relative;
  margin-top: 50%;
  transform: translateY(-50%);
}

.tanDialog {
  position: absolute; 
  background-color: black;
  opacity: 0.85;
  top: 20%;
  left: 20%;
  width: 60%;
  height: 60%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 15;
}

.msgZindex {
  z-index: 8888;
}


/*整个滚动条样式*/ 
::-webkit-scrollbar {
  width: 0px;   
  height: 0px;
  /* width: 5px;
  height: 8px; */
  background-color: #fff;
}
/*定义滚动条轨道*/
::-webkit-scrollbar-track {
  background-color: #fff;
}
/*定义滑块*/
::-webkit-scrollbar-thumb {
  background-color: #ACB1BF;
  border-radius: 4px;
}
